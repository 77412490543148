import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProductList from '../components/product-list';

import './products.scss';

const Products = () => (
  <Layout showBasket categories="Shop">
    <SEO title="Products" />
    <ProductList />
  </Layout>
);

export default Products;
