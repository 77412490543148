import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './product.scss';

const Product = ({
  productData: {
    title, slug, featuredImage, productDetails,
  },
} :
  any) => {
  const image = getImage(featuredImage?.node.localFile);
  return (
    <div className="product-teaser">
      <Link to={slug}>
        <div className="product-teaser-image">
          <GatsbyImage image={image} alt={featuredImage?.node.altText || ''} />
          {!productDetails.inStock ? (
            <div className="product-teaser-stock-indicator">Out of stock</div>
          ) : ''}
        </div>
        <div className="product-teaser-title">{title}</div>
      </Link>
    </div>
  );
};

export default Product;
