import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Product from '../product';

const ProuductsPagePure = ({ data }: any) => (
  <div className="full-page-width-wrapper products-list">
    { data.map(({ product }: any) => (
      <Product productData={product} key={product.slug} />
    )) }
  </div>
);

export default () => (
  <StaticQuery
    query={graphql`
    query WpProductsList {
      # Query all WordPress blog posts sorted by date
      allWpProduct(sort: { fields: [date], order: DESC }) {
        edges {
          product: node {
            id
            slug
            title
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 450
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }

            productDetails {
              inStock
            }
          }
        }
      }
    }
    `}
    render={(data) => <ProuductsPagePure data={data.allWpProduct.edges} />}
  />
);
